import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="m-auto text-5xl font-serif">NOT FOUND</h1>
  </Layout>
);
